<template>
  <div
    class="fixed z-40 top-0 bottom-0 left-0 right-0 h-full w-full bg-black bg-opacity-50 flex justify-end"
  >
    <div
      class="w-3/4 h-full cursor-pointer"
      @click="$emit('close')"
      :class="step === 5 ? 'w-1/4' : ''"
    ></div>
    <div
      class="w-1/4 h-full bg-white rounded-l-2xl p-10 overflow-auto"
      :class="step === 5 ? 'w-3/4' : ''"
    >
      <div class="flex items-center justify-between">
        <h1 class="text-black font-bold text-xl">
          {{ modalTitle }}
        </h1>
        <div
          class="bg-red-500 bg-opacity-30 p-5 rounded-full cursor-pointer"
          @click="$emit('close')"
        >
          <CloseIcon />
        </div>
      </div>
      <addmission-data
        :corpse_id="corpse.corpse_id"
        :corpseData="profile"
        @created="profileUpdated"
        v-if="step === 1"
      />
      <embalment-form
        v-else-if="step === 2"
        :corpse_id="corpse.corpse_id"
        :name="corpse.name_of_deceased"
        @created="embalment"
      />
      <funeral-service
        :corpse="corpse"
        @created="funeral"
        v-else-if="step === 3"
      />
      <billing-sheet-modal
        v-else-if="step === 4"
        :corpse="corpse"
        :name="corpse.contact_person"
        @created="billingSheet"
      />
      <invoice v-else :invoice="invoice" />
    </div>
  </div>
</template>

<script>
export default {
  name: "EditCorpse",
  components: {
    CloseIcon: () => import("@/assets/icons/CloseIcon.vue"),
    AddmissionData: () => import("./AddmissionData.vue"),
    EmbalmentForm: () => import("./Embalment.vue"),
    FuneralService: () => import("./FuneralService.vue"),
    BillingSheetModal: () => import("./BillingSheetModal.vue"),
    Invoice: () => import("./Invoice.vue"),
  },
  props: {
    corpse: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      step: 1,
    };
  },
  methods: {
    profileUpdated(corpse) {
      this.corpse = { ...corpse };
      if (corpse.embalmment_status) {
        this.step++;
      } else if (corpse.funeral_service) {
        this.step = 3;
      } else {
        this.step = 4;
      }
    },
    embalment(corpse) {
      this.corpse = { ...corpse };
      if (corpse.funeral_service) {
        this.step = 3;
      } else {
        this.step = 4;
      }
    },
    funeral() {
      this.step = 4;
    },
    billingSheet(e) {
      this.invoice = e;
      this.step = 5;
    },
  },
  computed: {
    modalTitle() {
      switch (this.step) {
        case 1:
          return "Create Profile";
        case 2:
          return "Embalment form";
        case 3:
          return "Funeral Services";
        case 4:
          return "Billing Sheet";
        default:
          return "Invoice for Morgue Services";
      }
    },
    profile() {
      const corpse = (({
        corpse_id,
        image,
        name_of_deceased,
        age_of_deceased,
        gender_of_deceased,
        cause_of_death,
        time_of_admission,
        date_of_admission,
        contact_person,
        contact_person_phone,
        fridge_number,
        embalmment_status,
        funeral_service,
        repatriation,
        personal_possession_on_arrival,
        comment,
      }) => ({
        corpse_id,
        image,
        name_of_deceased,
        age_of_deceased,
        gender_of_deceased,
        cause_of_death,
        time_of_admission,
        date_of_admission,
        contact_person,
        contact_person_phone,
        fridge_number,
        embalmment_status,
        funeral_service,
        repatriation,
        personal_possession_on_arrival,
        comment,
      }))(this.corpse);
      return corpse;
    },
  },
};
</script>
